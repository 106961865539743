import * as t from 'io-ts'

/** Product Kind enum - Describes product kind (GROUND_PHOTO, COMMERCIAL_STAGING, MEETING_ON_SITE, etc...)  */
export enum ProductKind {
  // MARK: - Core

  GROUND_PHOTO = 'GROUND_PHOTO',
  STANDARD_GROUND_VIDEO = 'STANDARD_GROUND_VIDEO',
  DRONE_PHOTO = 'DRONE_PHOTO',
  STANDARD_DRONE_VIDEO = 'STANDARD_DRONE_VIDEO',
  STANDARD_GROUND_AND_DRONE_VIDEO = 'STANDARD_GROUND_AND_DRONE_VIDEO',
  GROUND_PHOTO_PRESTIGE = 'GROUND_PHOTO_PRESTIGE',
  PRESTIGE_GROUND_AND_DRONE_VIDEO = 'PRESTIGE_GROUND_AND_DRONE_VIDEO',
  OTHER = 'OTHER',
  BUNDLE = 'BUNDLE',
  MAST_PHOTO = 'MAST_PHOTO',
  REEL_VIDEO = 'REEL_VIDEO',
  TEASER_VIDEO = 'TEASER_VIDEO',

  // MARK: - Options

  RESIDENTIAL_STAGING = 'RESIDENTIAL_STAGING',
  COMMERCIAL_STAGING = 'COMMERCIAL_STAGING',
  RESIDENTIAL_RENDERING = 'RESIDENTIAL_RENDERING',
  COMMERCIAL_RENDERING = 'COMMERCIAL_RENDERING',
  MATTERPORT_FLOOR_PLAN_WITH_VISIT = 'MATTERPORT_FLOOR_PLAN_WITH_VISIT',
  MATTERPORT_FLOOR_PLAN_WITHOUT_VISIT = 'MATTERPORT_FLOOR_PLAN_WITHOUT_VISIT',
  MATTERPORT_PHOTO = 'MATTERPORT_PHOTO',
  MATTERTAG = 'MATTERTAG',
  PHOTO = 'PHOTO',
  PORTRAIT = 'PORTRAIT',
  CORPORATE = 'CORPORATE',
  MOBILE_APPLICATION_FLOOR_PLAN = 'MOBILE_APPLICATION_FLOOR_PLAN',
  FLOOR_PLAN_EDITING_2D = 'FLOOR_PLAN_EDITING_2D',
  FLOOR_PLAN_EDITING_3D = 'FLOOR_PLAN_EDITING_3D',
  SLIDESHOW = 'SLIDESHOW',

  // MARK: - Admin

  HOSTING = 'HOSTING',
  DRONE_AUTHORIZATION = 'DRONE_AUTHORIZATION',

  // MARK: - Quality

  SD = 'SD',
  HD = 'HD',

  // MARK: - Extras

  HOUR_ON_SITE = 'HOUR_ON_SITE',
  EQUIPMENT = 'EQUIPMENT',

  // MARK: - Delivery speed

  FAST_DELIVERY = 'FAST_DELIVERY',

  // MARK: - Organization

  MEETING_ON_SITE = 'MEETING_ON_SITE',
  ORGANIZATION_THIRD_PARTY = 'ORGANIZATION_THIRD_PARTY',
  KEYS_PICKUP = 'KEYS_PICKUP',

  // MARK: - Billing

  EXTERNAL_REPORTING = 'EXTERNAL_REPORTING',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  INVOICE_BY_EMAIL = 'INVOICE_BY_EMAIL',
  INVOICE_BY_POST = 'INVOICE_BY_POST',
  STRIPE_PAYMENT = 'STRIPE_PAYMENT',
  PERIODICAL_INVOICE = 'PERIODICAL_INVOICE',
  SEPA_DIRECT_DEBIT = 'SEPA_DIRECT_DEBIT',
  CARD_PAYMENT = 'CARD_PAYMENT',
  FAILED_SEPA_DIRECT_DEBIT = 'FAILED_SEPA_DIRECT_DEBIT',

  // MARK: Book CT

  FAVOURITE_CREATIVE = 'FAVOURITE_CREATIVE',

  // MARK: - Special type for purchase flow.

  NONE = 'NONE',

  // MARK: Helper kind just for FE

  BILLING_ADDRESS = 'BILLING_ADDRESS',
  VAT_NUMBER = 'VAT_NUMBER',
  PRODUCTION_COMMENTS = 'PRODUCTION_COMMENTS',
  REFERENCE = 'REFERENCE',
  PROPERTY_OWNER = 'PROPERTY_OWNER',

  // MARK: - Documents

  AUTHORITIES_DOCUMENTS = 'AUTHORITIES_DOCUMENTS',
  FLOOR_PLAN_CERTIFICATION = 'FLOOR_PLAN_CERTIFICATION', // -- floor plan certification
  EXTRA_CERTIFIED_FLOOR = 'EXTRA_CERTIFIED_FLOOR', // -- floor plan certification
  EXTRA_CERTIFIED_ROOM = 'EXTRA_CERTIFIED_ROOM', // -- floor plan certification
  EXTRA_ARCHITECT_STAMP = 'EXTRA_ARCHITECT_STAMP', // -- floor plan certification
  MEASUREMENT_ON_SITE = 'MEASUREMENT_ON_SITE',
  EXTRA_DETACHED_HOUSE_TYPE = 'EXTRA_DETACHED_HOUSE_TYPE',
  EXTRA_SINGLE_FLOOR_HOUSE_TYPE = 'EXTRA_SINGLE_FLOOR_HOUSE_TYPE',
  EXTRA_SURFACE_MEASUREMENT = 'EXTRA_SURFACE_MEASUREMENT',
  EXTRA_MEASURED_ROOM = 'EXTRA_MEASURED_ROOM',
  EXTRA_MEASURED_FLOOR = 'EXTRA_MEASURED_FLOOR',
  EXTRA_MEASURED_BASEMENT = 'EXTRA_MEASURED_BASEMENT',
  EXTRA_MEASURED_ATTIC = 'EXTRA_MEASURED_ATTIC',
  EXTRA_MEASURED_RESIDENTIAL_UNIT = 'EXTRA_MEASURED_RESIDENTIAL_UNIT',
  EXTRA_LAND_REGISTRATION_SEARCH = 'EXTRA_LAND_REGISTRATION_SEARCH',
  EXTRA_OFFICE_FEE = 'EXTRA_OFFICE_FEE',

  // MARK: - Energy certificates
  ENERGY_CERTIFICATE = 'ENERGY_CERTIFICATE',
  EXTRA_SINGLE_FAMILY_HOUSE_2ND_FLOOR = 'EXTRA_SINGLE_FAMILY_HOUSE_2ND_FLOOR', // measurement on site - property type extra
  PHOTO_BLURRING = 'PHOTO_BLURRING',
}

export const ProductKindType = t.keyof(ProductKind)
export type ProductKindType = t.TypeOf<typeof ProductKindType>