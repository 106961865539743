import * as t from 'io-ts'

/** Product Type enum - Describes product type (PHOTO, VIDEO, VIRTUAL_VISIT, FLOOR_PLAN, NONE) */
export enum ProductType {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
  VIRTUAL_VISIT = 'VIRTUAL_VISIT',
  FLOOR_PLAN = 'FLOOR_PLAN',
  STAGING_360 = 'STAGING_360',

  // DOCUMENTS
  CONSTRUCTION_FILE = 'CONSTRUCTION_FILE',
  LAND_REGISTRATION = 'LAND_REGISTRATION',
  PROTECTION = 'PROTECTION',
  MUNICIPAL = 'MUNICIPAL',
  DOCUMENTATION = 'DOCUMENTATION',
  LAW_INQUIRIES = 'LAW_INQUIRIES',
  ENERGY_CERTIFICATE_OF_NEED = 'ENERGY_CERTIFICATE_OF_NEED',
  CONSUMPTION_CERTIFICATE = 'CONSUMPTION_CERTIFICATE',

  // BUNDLES
  GROUND_PHOTO_DRONE_PHOTO_BUNDLE = 'GROUND_PHOTO_DRONE_PHOTO_BUNDLE',
  GROUND_PHOTO_STAGING_BUNDLE = 'GROUND_PHOTO_STAGING_BUNDLE',
  GROUND_PHOTO_FLOOR_PLAN_BUNDLE = 'GROUND_PHOTO_FLOOR_PLAN_BUNDLE',
  CUSTOM_CLIENT_BUNDLE = 'CUSTOM_CLIENT_BUNDLE',
  PHOTO_VIDEO_TEASER_BUNDLE = 'PHOTO_VIDEO_TEASER_BUNDLE',
  PHOTO_VIRTUAL_VISIT_BUNDLE = 'PHOTO_VIRTUAL_VISIT_BUNDLE',
  PHOTO_SEVERAL_FLATS_AT_ONE_ADDRESS = 'PHOTO_SEVERAL_FLATS_AT_ONE_ADDRESS',
  PHOTO_FLOOR_PLAN_VIDEO_TEASER = 'PHOTO_FLOOR_PLAN_VIDEO_TEASER',

  /** Special type for purchase flow. */
  NONE = 'NONE',
}
export const ProductTypeType = t.keyof(ProductType)
export type ProductTypeType = t.TypeOf<typeof ProductTypeType>